import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoAdapt.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import LogoIonic from '../../assets/ionic.svg';
import LogoLeaflet from '../../assets/leafletLogo.svg';
import LogoGoogleMaps from '../../assets/googleMapsLogo.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const Adapt = () => {
  const {desktopHome, mobileJobs2, ipadJobs} = useStaticQuery(graphql`
    query {
      desktopHome: file(relativePath: { eq: "adapt/adaptDashboard.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileJobs2: file(relativePath: { eq: "adapt/mobileJobs2.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ipadJobs: file(relativePath: { eq: "adapt/ipadJobs.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Integrating multiple systems to increase productivity and efficiency</h2>
        <p>
          Adapt have always used technology to their advantage and to stay ahead of their competitors. With a growing 
          business they needed to mobilise their workforce and increase the efficiency of their day to day operations.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>
            We started working with Adapt in 2016 and have implemented multiple projects to integrate with their existing systems.
            The first was a compliance dashboard to give Adapt customers complete visibility of their facilities and assets.
            This project involved integrating with existing systems to visualise and present data online, thus removing the need 
            for ad-hoc customer reporting and giving Adapt a unique advantage over their competitors.</p>
            
          <p>The second was an iOS app to facilitate in-field data capture, job management and time tracking.
          This app has removed the need for paper based job cards and manual data entry and has enabled Adapt to focus on what they do best.
          </p>
        </div>

        <img src={Logo} alt="Adapt logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with Adapt to integrate with their existing systems and build solutions including:</p>
          <ul>
              <li>Customer Compliance Dashboard</li>
              <li>Job Card and Time Sheet App</li>
              <li>Equipment Testing App</li>
              <li>Offline Capability with Auto Sync</li>
              <li>SMS Messaging</li>
          </ul>
          <p>We also assist with the ongoing stability of the solutions including:</p>
          <ul>
            <li>Support and Maintenance</li>
            <li>Ongoing Enhancements</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={desktopHome.sharp.fluid} fadeIn alt="Dashboard page" />
          <Img fluid={ipadJobs.sharp.fluid} fadeIn alt="Jobs page on iPad" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <ul>
              <li>Web Application Development</li>
              <li>Mobile App Development</li>
              <li>Systems Integration</li>
              <li>Process Automation</li>
              <li>UX Design</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={mobileJobs2.sharp.fluid} fadeIn alt="Jobs page on mobile" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
            <img src={LogoIonic} alt="Ionic logo" />
            <img src={LogoLeaflet} alt="Leaflet logo" />
            <img src={LogoGoogleMaps} alt="Google Maps logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          It's great to work with a local team that really understand business and technology.
          Together we've built a rock solid platform that has improved our productivity immensely.
        </ProjectQuote>
      </ProjectWrapper>

      <ProjectNav>
        <div className="prev">
          <img src={NavArrow} />
          <Link to="/work/bhp">BHP</Link>
        </div>
        <div className="next">
          <Link to="/work/sawu">Sawu</Link>
          <img src={NavArrow} />
        </div>
      </ProjectNav>
    </Layout>
  );
};

export default Adapt;
